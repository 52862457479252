import { computed, ref } from '@vue/composition-api'
import useLocaleValidation from '@/views/utils/useLocaleValidation'

export default function usePassword() {
  useLocaleValidation()
  const passwordFieldType = ref('password')
  const passwordToggleIcon = computed(() => (passwordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))
  const togglePasswordVisibility = () => {
    passwordFieldType.value = passwordFieldType.value === 'password'
      ? 'text' : 'password'
  }

  return {
    passwordFieldType,
    passwordToggleIcon,
    togglePasswordVisibility,
  }
}
